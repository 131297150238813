import React from 'react'
import "./ShareQtestComponent.scss"
import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Context from "../../context/Context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { FEURL } from "../../api/BaseUrl";
import TitleComponent from '../../shared/titleComponent/TitleComponent';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import InviteModalWrapper from '../../shared/inviteModalComponent/InviteModalWrapper';
import ExitWarning from '../../shared/exitWarning/ExitWarning';
import { isStateValid } from '../../shared/constants';

const useStyles = makeStyles({
  button: {
    padding: "0.5rem 1.5rem",
    fontFamily: "CaustenR",
    fontSize: "1.5rem",
    fontWeight: "700",
    color: "white",
    borderRadius: "10px",
    // "@media only screen and (max-width: 1536px)": {
    //   fontSize: "1.4rem",
    //   fontWeight: "400",
    //   fontFamily: "CaustenEB",
    //   padding: "1rem 1.5rem",
    // },
    "@media only screen and (max-width: 900px)": {
      fontSize: "1rem",
      fontWeight: "400",
      fontFamily: "CaustenEB",
      padding: "0.4rem 2rem",
    }
    // ,
    // "@media only screen and (max-width: 600px)": {
    //   fontSize: "0.875rem",
    //   fontWeight: "400",
    //   fontFamily: "CaustenR",
    //   padding: "0.4rem 1.2rem",
    // }
  }
})

const ShareQtestComponent = () => {
  const {
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
    isLoggedIn
  } = useContext(Context);
  const navigate = useNavigate()
  const classes = useStyles()
  const [exitModalOpen, setExitModalOpen] = useState(false);
  let {qtestId} = useParams();
  let backToPath = "/quizselection";
  qtestId = qtestId.trim().toLowerCase();

  // Get params from navigate
  const {state} = useLocation();
  if(isStateValid(state)){ // Check if state is valid
    // Read values passed on state if present
    if(state.hasOwnProperty("backToPath")){
      backToPath = state.backToPath;
    }
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  // setAlert to modify notifications before display
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  const selectInviteLink = () => {
    // Select the Invite Link text
    var range = document.createRange();
    var selection = window.getSelection();
    range.selectNodeContents(document.getElementById("inviteLink"));
    selection.removeAllRanges();
    selection.addRange(range);
  }

  var shareLinkClipboard = new window.ClipboardJS(".copyBtn")
  shareLinkClipboard.on('success', function (e) {
    setTimeout(() => {
      setAlert("info", "Link copied to clipboard");
      selectInviteLink();
    }, 100)
  })

  const handleBackClick = () => {
      navigate(backToPath);
  }

  useEffect(()=>{
    if(!isLoggedIn){
      navigate("/")
    }
  },[])

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to leave the game?")) {
        setfinishStatus(true)
        navigate("/");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false)
      }
    }
  }

  const onReloadButtonEvent = (e) => {
    e.preventDefault();
    return e.returnValue = "Data will be lost if you leave the page, are you sure?";
  };

  const [finishStatus, setfinishStatus] = useState(false);
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    window.addEventListener('beforeunload', onReloadButtonEvent);
    //scroll bar style
    let main = document.getElementById("main")
    main.classList.add("main-green");
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
      window.removeEventListener('beforeunload', onReloadButtonEvent);
      //scroll bar style
      let main = document.getElementById("main")
      main.classList.remove("main-green");
    };
  }, []);

  return (
    <div className="waitingroom">
      <TitleComponent title="Your QTest is Ready" handleOnClick={handleBackClick} align="left" style={{ marginRight: "auto", marginLeft: isTablet ? "14%" : '10%' }} />
      <div className="waitingroom__hostActions">
        <div className="waitingroom__linkAndShare">
          <div className="waitingroom__link">
            <p id="inviteLink">{`${FEURL}/qtest/${qtestId}`}</p>
            <Button
              className="copyBtn"
              data-clipboard-text={`${FEURL}/qtest/${qtestId}`}
            >
              <span style={{ marginLeft: "auto" }} className="waitingroom__copyButton">copy</span>
            </Button>
          </div>
          <InviteModalWrapper waitingRoom qtestId={qtestId}/>
        </div>
        <p className="waitingroom__infoText" style={{ padding: "1.5rem 0", }}>
          Copy the link above and share it with your friends for them to participate in
          the QTest.
        </p>
      </div>
    
      <div className="waitingroom__noPlayers">
        <img src="/images/qTNoPlayers.png" alt="qTNoPlayers" />
      </div>

      <div className="waitingroom__buttonContainer">
        <Button
          onClick= {() => {
            navigate("/")
          }}
          className={classes.button}
          style={{
            backgroundColor: "#fdb913",
          }}
        >
          Home
        </Button>
      </div>

      <ExitWarning modalOpen={exitModalOpen} setModalOpen={setExitModalOpen} />
    </div>
  );
};

export default ShareQtestComponent;
