import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Context from "./Context";
import { WSURL } from "./../api/BaseUrl";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";

const Provider = (props) => {

  const navigate = useNavigate();

  const [socket, setSocket] = useState(null);
  const [userName, setUserName] = useState("");
  const [playerAnswerCount, setPlayerAnswerCount] = useState(0);
  const [playerCorrectAnswerCount, setPlayerCorrectAnswerCount] = useState(0);

  //new states
  const [questionData, setQuestionData] = useState({});
  const [answerData, setAnswerData] = useState({});
  const [leaderboardData, setLeaderboardData] = useState({});
  const [showAnswerPage, setShowAnswerPage] = useState(false);
  const [timer, setTimer] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [podiumPage, setPodiumPage] = useState(false);
  const [leaderBoardOpen, setLeaderBoardOpen] = useState(false);
  const [mobileLeaderBoardOpen, setMobileLeaderBoardOpen] = useState(false);
  const [noOfQuestions, setNoOfQuestions] = useState(5);
  const [noOfCorrectAns, setNoOfCorrectAns] = useState(0);
  const [quizType, setQuizType] = useState("");
  const [quizMode, setQuizMode] = useState("");
  const [continueQuizzing, setContinueQuizzing] = useState(false);
  const [timerProgress, setTimerProgress] = useState(0);
  const [maxTime, setMaxTime] = useState(30);  
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [editQuestionsList, setEditQuestionsList] = useState([])
  const [editQuizDetails, setEditQuizDetails] = useState({})
  const [activeSlides, setActiveSlides] = useState([])
  const [dragFlag, setDragFlag] = useState(false)
  

  // Setting initial values for notification
  const [notificationType, setNotificationType] = useState("info");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDisplayFlag, setNotificationDisplayFlag] = useState(false);
  const [notificationDuration, setNotificationDuration] = useState(5000);
  // setAlert to modify notifications before display
  const setAlert = (type, message, duration = notificationDuration) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
    setNotificationDuration(duration)
  };

  // Setting initial values for notification
  const [loadingDisplayFlag, setLoadingDisplayFlag] = useState(false);

  // useEffect to run socket functions on socket change
  useEffect(() => {
    if (socket != null) {
      connectQuiz();
    }
  }, [socket]);

  const [roomId, setRoomId] = useState(null);
  const [host, setHost] = useState(false);
  const [socketClosed, setSocketClosed] = useState(false);

  const [networkStatus, setNetworkStatus] = useState(true);
  const [disconnect, setDisconnect] = useState(false);

  useEffect(() => {
    if (disconnect && networkStatus) {
      if (socket == null) {
        try {
          const socket = new WebSocket(`${WSURL}/ws/quiz/${roomId}/`)
          setSocket(socket)
        } catch (err) {
          console.log(err);
          socket.close();
          setSocket(null);
        }
      }
    }
  }, [disconnect, networkStatus, socket]);

  useEffect(() => {    
    const handleStorageChange = () => {
      setIsLoggedIn(localStorage.getItem('isLoggedIn') === 'true');
    };
    window.addEventListener('storage', handleStorageChange);
    handleStorageChange();
    let qqIdToken = Cookies.get('qq_id_token')
    if (qqIdToken == null) {  // if no token make the user login again      
      setIsLoggedIn(false)
      localStorage.setItem('isLoggedIn', false)
    }else{  
      let decodedToken = jwt.decode(qqIdToken);
      setUserName(decodedToken['cognito:username'])
      sessionStorage.setItem('sessionId', decodedToken['sub'])
      sessionStorage.setItem("name", Cookies.get("name"));
      sessionStorage.setItem("email", Cookies.get("email"));
      sessionStorage.setItem("ageGroup", Cookies.get("ageGroup"));
      sessionStorage.setItem("avatar", Cookies.get("avatar"));
    }      
  }, [])

  const chanageNetworkStatus = (status) => {
    console.log("network status ", status);
    setNetworkStatus(status);
  }

  useEffect(() => {
    window.addEventListener("online", () => chanageNetworkStatus(true));
    window.addEventListener("offline", () => chanageNetworkStatus(false));
    return () => {
      window.removeEventListener("online", () => chanageNetworkStatus(true));
      window.removeEventListener("offline", () => chanageNetworkStatus(false));
    };
  }, []);

  // websocket action handlers
  const onConnectedToRoom = () => {
    if (!disconnect) {
      socket.send(
        JSON.stringify({
          action: "addPlayer",
          playerName: sessionStorage.getItem("name"),
          playerEmail: sessionStorage.getItem("email"),
          playerId: sessionStorage.getItem("sessionId"),
          playerAvatar: sessionStorage.getItem("avatar"),
          isHost: sessionStorage.getItem("isHost")
            ? sessionStorage.getItem("isHost")
            : false,
          categories: JSON.parse(sessionStorage.getItem("categories")),
          ageGroup: sessionStorage.getItem("ageGroup")
        })
      );
    }
    else {
      socket.send(
        JSON.stringify({
          action: "rejoinPlayer",
          playerId: sessionStorage.getItem("sessionId"),
        })
      );
      setDisconnect(false);
    }
  };

  const hostExitAlertMessage = () => {
    setLoadingDisplayFlag(true);
    setAlert("error", "Uh oh! Looks like the host has left the game. You will now be redirected to the quiz homepage - Start your own quiz!", 8000);
    setTimeout(() => {
      navigate("/");
      setLoadingDisplayFlag(false);
    }, 8000);

  }

  const onHostExit = (data) => {
    if (data.hasOwnProperty("payload")) {
      hostExitAlertMessage();
    } else {
      setAlert("error", "Data has no 'payload' property.");
    }
  };

  const onSendQuestion = (data) => {
    if (data.hasOwnProperty("payload")) {
      setMaxTime(data.payload.currentQuestion.timeToAnswer)
      setQuestionData(data.payload.currentQuestion);
      setSelectedAnswer("");
      setPlayerAnswerCount(0);
      setPlayerCorrectAnswerCount(0);
      setShowAnswerPage(false);
      setMobileLeaderBoardOpen(false);
      setNoOfQuestions(data.payload.currentQuestion.totalNoOfQuestions)
    } else {
      setAlert("error", "Data has no 'payload' property.");
    }
  };

  const onSendAnswer = (data) => {
    if (data.hasOwnProperty("payload")) {
      if (data.payload.answerData.questionType === "NQS"){
        return
      }
      setAnswerData(data.payload.answerData);
      setShowAnswerPage(true);
    } else {
      setAlert("error", "Data has no 'payload' property.");
    }
  };

  const onSendLeaderboard = (data) => {
    if (data.hasOwnProperty("payload")) {
      setLeaderboardData(data.payload);
      setPlayerAnswerCount(data.payload.playerAnsweredCount);
      setPlayerCorrectAnswerCount(data.payload.playerCorrectAnswerCount)
    } else {
      setAlert("error", "Data has no 'payload' property.");
    }
  };

  const onSendPodium = (data) => {
    setPodiumPage(true);
  };

  const onTimerUpdate = (data) => {
    data.hasOwnProperty("payload")
      ? setTimer(data.payload)
      : setAlert("error", "Data has no 'payload' property.");
  };

  const onError = (data) => {
    if (data.hasOwnProperty("payload")) {
      console.log(data.payload.errorText);
      if (data.payload.errorText == "Room Closed") {
        hostExitAlertMessage();
      } else if (data.payload.errorText == "Room Full"){
        setAlert("error", "Unable to join. Room is full")
        setTimeout(() => {
          navigate('/')
        }, 2000);
      } else {
        setAlert("error", data.payload.errorText)
      }
    } else {
      setAlert("error", "Data has no 'payload' property.");
    }
  };

  const onContinueQuizzing = () => {
    setContinueQuizzing(true)
  }

  const onRoomFull = () => {
    setTimeout(() => {
      navigate('/')
    }, 2000);
  }

  // websocket action handlers

  // function for running websocket functions
  const connectQuiz = () => {
    console.log(socket, "socket");
    socket.onopen = () => {
      console.log("socket onopen");
      setAlert(
        "info",
        disconnect ? ("Welcome back! Let's continue with the quiz!") :
          (host ? "You created and joined the room." : "You joined the room.")
      );
      setDisconnect(false);
      socket.onmessage = (e) => {
        const data = JSON.parse(e.data);
        console.log("websocket data", data);
        if (!data.hasOwnProperty("action")) {
          setAlert("error", "Data has no 'action' property.");
          return;
        }
        if (data.action == "connected to room") {
          onConnectedToRoom();
        } else if (data.action == "hostExited") {
          onHostExit(data);
        } else if (data.action == "sendQuestion") {
          onSendQuestion(data);
        } else if (data.action == "sendAnswer") {
          onSendAnswer(data);
        } else if (data.action == "sendLeaderboard") {
          onSendLeaderboard(data);
        } else if (data.action == "timerUpdate") {
          onTimerUpdate(data);
        } else if (data.action == "sendPodium") {
          onSendPodium(data);
        } else if (data.action == "continueQuizzing") {
          onContinueQuizzing();
        } else if (data.action == "error") {
          onError(data);
        } else if (data.action == "roomFull") {
          onRoomFull();
        } else {
          setAlert("error", "Data 'action' property is not handled.");
        }
      };
      socket.onclose = function (e) {
        setSocket(null);
        console.log("socket disconnected, code:", e.code);
        // console.log("disconnect code ", e.code);
        if (e.code == 1006) {
          setDisconnect(true);
          setAlert("warning", "You seem to be offline. The quiz will resume once you are back online.");
        } else {
          setAlert("warning", "You left the room.");
        }
      };

      socket.onerror = (e) => {
        console.log("onerror1 ", e);
        setSocket(null);
      };
    };
    socket.onerror = (e) => {
      console.log("onerror2 ", e);
      setSocket(null);
    };
  };

  function check() {
    if (!setSocketClosed && socket?.readyState == 3) {
      console.log("connecting.........");
      connectQuiz();
    }
  }
  setInterval(check, 5000);

  return (
    <Context.Provider
      value={{
        socket,
        setSocket,
        roomId,
        setRoomId,
        host,
        setHost,
        notificationType,
        setNotificationType,
        notificationMessage,
        setNotificationMessage,
        notificationDisplayFlag,
        setNotificationDisplayFlag,
        notificationDuration,
        setNotificationDuration,
        loadingDisplayFlag,
        setLoadingDisplayFlag,
        playerAnswerCount,
        setPlayerAnswerCount,
        playerCorrectAnswerCount,
        setPlayerCorrectAnswerCount,
        userName,
        setUserName,
        socketClosed,
        setSocketClosed,
        questionData,
        setQuestionData,
        answerData,
        setAnswerData,
        leaderboardData,
        setLeaderboardData,
        timer,
        setTimer,
        timerProgress,
        setTimerProgress,
        maxTime,
        setMaxTime,
        showAnswerPage,
        setShowAnswerPage,
        selectedAnswer,
        setSelectedAnswer,
        podiumPage,
        setPodiumPage,
        leaderBoardOpen,
        setLeaderBoardOpen,
        mobileLeaderBoardOpen,
        setMobileLeaderBoardOpen,
        disconnect,
        setDisconnect,
        quizType,
        setQuizType,
        quizMode, 
        setQuizMode,
        noOfQuestions,
        noOfCorrectAns,
        setNoOfCorrectAns,
        continueQuizzing, 
        setContinueQuizzing,
        isLoggedIn, 
        setIsLoggedIn,
        editQuestionsList,
        setEditQuestionsList,
        editQuizDetails,
        setEditQuizDetails,
        activeSlides, 
        setActiveSlides,
        dragFlag,
        setDragFlag,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default Provider;
