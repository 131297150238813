import React, { useState } from "react";
import { Slider } from "@mui/material";
import "./SliderComponent.scss";

const SliderComponent = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSliderChange = (newValue) => {
    setCurrentIndex(newValue);
  };

  const valueLabelFormat = (value) => {
    return images[value].label;
  };

  return (
    <div className="slider">
      <div className="slider__container">
        <div
          className="slider__content"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {images.map((image, index) => (
            <div className="slider__slide" key={index}>
              <p dangerouslySetInnerHTML={{ __html: image.text }}></p>
              <img src={image.src} alt={image.label} />
            </div>
          ))}
        </div>
        <Slider
          aria-label="Image Slider"
          value={currentIndex}
          onChange={(e, value) => handleSliderChange(value)}
          step={1}
          min={0}
          max={images.length - 1}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
          sx={{ width: "80%", mt: 2, mb: 2 }}
        />
      </div>
    </div>
  );
};

export default SliderComponent;
