import React from 'react'
import "./WaitingRoomComponent.scss"
import { Button, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";
import Context from "./../../context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { BEURL, FEURL } from "./../../api/BaseUrl";
import { Box } from "@mui/system";
import TitleComponent from '../../shared/titleComponent/TitleComponent';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CustomModalComponent from '../../shared/customModalComponent/CustomModalComponent';
import InviteModalWrapper from '../../shared/inviteModalComponent/InviteModalWrapper';
import ExitWarning from '../../shared/exitWarning/ExitWarning';
import axios from 'axios';
import apiEndpoints from "./../../api/EndPoints";
import  Tooltip from "@mui/material/Tooltip";
import { isStateValid } from '../../shared/constants';
import SliderComponent from '../../shared/sliderComponent/SliderComponent';
import { getSlideInfoFromRoomId } from '../../shared/constants';
import { FaInfoCircle } from "react-icons/fa";

const useStyles = makeStyles({
  button: {
    padding: "1rem 1.5rem",
    fontFamily: "CaustenR",
    fontSize: "1.8rem",
    fontWeight: "700",
    color: "white",
    borderRadius: "10px",
    // "@media only screen and (max-width: 1536px)": {
    //   fontSize: "1.4rem",
    //   fontWeight: "400",
    //   fontFamily: "CaustenEB",
    //   padding: "1rem 1.5rem",
    // },
    "@media only screen and (max-width: 900px)": {
      fontSize: "1rem",
      fontWeight: "400",
      fontFamily: "CaustenEB",
      padding: "0.8rem 1.2rem",
    },
    "@media only screen and (max-width: 600px)": {
      fontSize: "0.875rem",
      fontWeight: "400",
      fontFamily: "CaustenR",
      padding: "0.8rem 2rem",
    }
  },

  disableButton: {
    padding: "1rem 3.5rem",
    border: "1px solid grey",
    fontFamily: "CaustenR",
    fontSize: "1.8rem",
    fontWeight: "700",
    color: "grey",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#d3d3d3",
      color: "white",
      borderColor: "#d3d3d3",
    },
    cursor: "not-allowed",
    "@media only screen and (max-width: 900px)": {
      fontSize: "1rem",
      fontFamily: "CaustenEB",
      padding: "0.8rem 1.2rem",
    },
    "@media only screen and (max-width: 600px)": {
      fontSize: "0.875rem",
      fontWeight: "400",
      fontFamily: "CaustenR",
      padding: "0.8rem 2rem",
    }
  }
})

const WaitingRoomComponent = () => {
  const {
    socket,
    leaderboardData,
    userName,
    roomId,
    host,
    questionsList,
    questionData,
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
    answerData,
    setContinueQuizzing,
    podiumPage,
    isLoggedIn
  } = useContext(Context);
  const navigate = useNavigate()
  const classes = useStyles()
  const [exitModalOpen, setExitModalOpen] = useState(false);

  // Get params from navigate
  let backToPath = "/quizselection";
  const {state} = useLocation();
  if(isStateValid(state)){ // Check if state is valid
    // Read values passed on state if present
    if(state.hasOwnProperty("backToPath")){
      backToPath = state.backToPath;
    }
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  // setAlert to modify notifications before display
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  // Handle solo quiz component open and close
  const [openSoloConfirm, setOpenSoloConfirm] = useState(false);
  const handleOpenSoloConfirm = () => setOpenSoloConfirm(true);
  const handleCloseSoloConfirm = () => setOpenSoloConfirm(false);

  const [howToJoinOpen, setHowToJoinOpen] = useState(false);
  const handleOpenHowToJoin = () => setHowToJoinOpen(true);
  const handleCloseHowToJoin = () => setHowToJoinOpen(false);

  const selectInviteLink = () => {
    // Select the Invite Link text
    var range = document.createRange();
    var selection = window.getSelection();
    range.selectNodeContents(document.getElementById("inviteLink"));
    selection.removeAllRanges();
    selection.addRange(range);
  }

  var shareLinkClipboard = new window.ClipboardJS(".copyBtn")
  shareLinkClipboard.on('success', function (e) {
    setTimeout(() => {
      setAlert("info", "Link copied to clipboard");
      selectInviteLink();
    }, 100)
  })

  const startQuiz = () => {
    socket !== null &&
      socket.send(
        JSON.stringify({
          action: "startQuiz",
          roomId: roomId,
        })
      );
    // update player host quiz stat
    socket !== null && isLoggedIn && axios.get(
      BEURL + apiEndpoints.updateUserStats,
      {
        params:{ action : "host"}
      }
    )    
  }

  const handleStartQuizClick = () => {
    if (leaderboardData?.playerStats?.length > 1) {
      startQuiz();
    }
    else {
      handleOpenSoloConfirm();
    }
  }

  const goToQuiz = () => {
    if ((!(Object.keys(questionData).length === 0 && questionData.constructor === Object) || !(Object.keys(answerData).length === 0 && answerData.constructor === Object))) {
      // navigate('/quiz')
      navigate('/getreadyroom')
    }
  }

  useEffect(() => {
    goToQuiz()
  }, [questionData, answerData])


  const goToPodium = () => {
    if (podiumPage) {
      navigate("/podium");
    }
  }

  useEffect(() => {
    goToPodium()
  }, [podiumPage]);


  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to leave the game?")) {
        setfinishStatus(true)
        navigate("/");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false)
      }
    }
  }

  const onReloadButtonEvent = (e) => {
    e.preventDefault();
    return e.returnValue = "Data will be lost if you leave the page, are you sure?";
  };

  const goToHome = () => {
    if (userName === "") {
      navigate("/")
    }
  }

  useEffect(() => {
    goToHome()
  }, [questionsList])


  const [finishStatus, setfinishStatus] = useState(false);
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    window.addEventListener('beforeunload', onReloadButtonEvent);
    //scroll bar style
    let main = document.getElementById("main")
    main.classList.add("main-green");
    setContinueQuizzing(host) //continueQuizzing  button in podium should be visible only to  host
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
      window.removeEventListener('beforeunload', onReloadButtonEvent);
      //scroll bar style
      let main = document.getElementById("main")
      main.classList.remove("main-green");
    };
  }, []);

  const handleBackClick = () => {
    if (host) {
      navigate(backToPath);
    } else {
      setExitModalOpen(true);
    }
  }

  return (
    <div className="waitingroom">
      <TitleComponent title="Waiting Room" handleOnClick={handleBackClick} align="left" style={{ marginRight: "auto", marginLeft: isTablet ? "14%" : '10%' }} />
      {host ? (
        <div className="waitingroom__hostActions">
          <div className="waitingroom__linkAndShare">
            <div className="waitingroom__link">
              <p id="inviteLink">{`${FEURL.split(/\/\/(.+)/)[1]}/${roomId}`}</p>
              <Button
                className="copyBtn"
                data-clipboard-text={`${FEURL}/${roomId}?utm_source=share`}
              >
                <span style={{ marginLeft: "auto" }} className="waitingroom__copyButton">copy</span>
              </Button>
            </div>
            <InviteModalWrapper waitingRoom />
            <Button
              className={`${classes.button} waitingroom__joinButton`}
              style={{
                backgroundColor: "#75b543",
                border: "2px solid white",
              }}
              onClick={handleOpenHowToJoin}
            >
              <FaInfoCircle size={"25"} />
            </Button>
            {(leaderboardData?.playerStats?.length > 1) && 
              <Tooltip title={leaderboardData?.playerStats?.length + " players in the room"} arrow>
                <div className="waitingroom__numberOfPlayers" style={{"marginLeft":"auto"}}>
                  <span className="playersIcon"><FaUsers fontSize={"xx-large"}/></span>
                  <span className="playersNumber">{leaderboardData?.playerStats?.length}</span>
                </div>
              </Tooltip>}
          </div>
          <div className='waitingroom__infoContainer'>
            <p className="waitingroom__infoContainer__infoText">
              Copy the link above and share it with your friends for them to join
              the quiz.
            </p>
            <p className="waitingroom__infoContainer__code">
              Room Code - &nbsp;  
              <span className="waitingroom__infoContainer__code__text">
                {roomId}
              </span>
            </p>
          </div>
        </div>
      ) : (
        <div className="waitingroom__hostActions">
          <p style={{ padding: "1.5rem 0", display:"flex", alignItems:"center"}}>
            {(leaderboardData?.playerStats?.length > 1) && 
              <Tooltip title={leaderboardData?.playerStats?.length + " players in the room"} arrow>
                <div className="waitingroom__numberOfPlayers" style={{marginLeft:"1.5rem", marginRight:"1.2rem"}}>
                  <span className="playersIcon"><FaUsers fontSize={"xx-large"}/></span>
                  <span className="playersNumber">{leaderboardData?.playerStats?.length}</span>
                </div>
              </Tooltip>}
            The host will start the quiz once everyone has joined
          </p>
        </div>
      )}

      {leaderboardData?.playerStats?.length > 1 ? (
        <Box
          sx={{
            border: "2px solid #4B9710",
            padding: "1rem",
            borderRadius: "1rem",
            overflowY: "auto",
            height: isMobile ? (host ? "42vh" : "65vh") : (host ? "51%" : "65%"),
            margin: isMobile ? "0 5% 2rem 5%" : "0 8% 2rem 8%",
            "@media only screen and (max-width: 600px)": {
              backgroundColor: "#58A01F",
              border: "none",
            },
            "@media only screen and (min-width: 600px) and (max-height: 756px)": { //if you change max-height also change it in the .css file in the media query
              height: host ? "12rem" : "22rem",
            },
            "&::-webkit-scrollbar": {
              width: "14px",
            },
            "::-webkit-scrollbar-thumb": {
              border: "4px solid rgba(0, 0, 0, 0)",
              backgroundClip: "padding-box",
              borderRadius: "9999px",
              backgroundColor: "#388200",
            }
          }}
        >
          <Grid container spacing={{ xs: 2, md: 3 }} rowSpacing={5}>
            {leaderboardData?.playerStats?.map((player, idx) => (
              <Grid item xs={4} sm={3} md={2.4} key={idx}>
                <div className="waitingroom__playerContainer">
                  <div className="waitingroom__playerAvatar">
                    <img src={player.playerAvatar} alt="avatar image" />
                  </div>
                  <p className='waitingroom__player'>{player.playerName}</p>
                  {leaderboardData.hostId === player.playerId && <p className='waitingroom__hostplayer'>Host</p>}
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>

      ) : (
        <div className="waitingroom__noPlayers">
          <img src="/images/qTNoPlayers.png" alt="qTNoPlayers" />
          <p>Waiting for others to join...</p>
        </div>
      )}

      {host && (
        <>
          <div className="waitingroom__buttonContainer">
            {/* <Button className={classes.disableButton}>PICK A CO-HOST</Button> */}
            <Button
              onClick={handleStartQuizClick}
              className={classes.button}
              style={{
                backgroundColor: "#fdb913",
              }}
            >
              START QUIZZING
            </Button>
          </div>
          <CustomModalComponent open={openSoloConfirm} handleClose={handleCloseSoloConfirm}>
            <div className="waitingroom__modal">
              <h1>Still have a chance to invite your friends!</h1>
              <div className="waitingroom__modalButtonContainer">
                <Button
                  onClick={handleCloseSoloConfirm}
                  className={classes.button}
                  style={{
                    backgroundColor: "#30B2E7",
                  }} >
                  Invite Friends
                </Button>

                <Button
                  onClick={startQuiz}
                  className={classes.button}
                  style={{
                    backgroundColor: "#30B2E7",
                  }}
                >
                  Quiz Solo
                </Button>
              </div>
            </div>
          </CustomModalComponent>
          <CustomModalComponent open={howToJoinOpen} handleClose={handleCloseHowToJoin}>
            <div className="waitingroom__modal">
              <h1>Instructions to join a quiz</h1>
              <h2>{
                    isMobile ? (
                      <span>{roomId}</span> 
                    ) : (
                      <>Room Code - <span>{roomId}</span></> 
                    )}
              </h2>
              <SliderComponent images={getSlideInfoFromRoomId(roomId)} />
              <Button
                onClick={handleCloseHowToJoin}
                className={classes.button}
                style={{
                  backgroundColor: "#30B2E7",
                }}
              >
              Close
              </Button>
            </div>
          </CustomModalComponent>
        </>

      )}
      <ExitWarning modalOpen={exitModalOpen} setModalOpen={setExitModalOpen} />
    </div>
  );
};

export default WaitingRoomComponent;
